import Vue from 'vue'
import VueRouter from 'vue-router'
import HmIndex from '@/views/HmIndex.vue'
import HmAbout from '@/views/HmAbout.vue'
import HmPatent from '@/views/HmPatent.vue'
import HmProduct from '@/views/HmProduct.vue'
import HmNews from '@/views/HmNews.vue'
import HmPractice from '@/views/HmPractice.vue'
import HmJoins from '@/views/HmJoins.vue'
import HmLogin from '@/views/HmLogin.vue' // 登录页
import HmRegister from '@/views/HmRegister.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/HmIndex'
  },
  {
    path: '/HmIndex',
    name: 'Index',
    component: HmIndex
  },
  {
    path: '/HmAbout',
    name: 'About',
    component: HmAbout
  },
  {
    path: '/HmPatent',
    name: 'Patent',
    component: HmPatent
  },
  {
    path: '/HmProduct',
    name: 'Product',
    component: HmProduct
  },
  {
    path: '/HmNews',
    name: 'News',
    component: HmNews
  },
  {
    path: '/HmPractice',
    name: 'Practice',
    component: HmPractice
  },
  {
    path: '/HmJoins',
    name: 'Joins',
    component: HmJoins
  },
  {
    path: '/HmLogin',
    name: 'Login',
    component: HmLogin
  },
  {
    path: '/HmRegister',
    name: 'Register',
    component: HmRegister
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
