<template>
  <div id="app">
    <el-carousel :interval="4000" type="card" lazy-load>
      <el-carousel-item v-for="item in src" :key="item.id">
        <!-- 轮播图中的图片 -->
        <img :src="item.href" alt="" class="carousel-img" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isAndroid: 'false'
    }
  },
  props: {
    src: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="less">
/* 基础设置：轮播图容器 */
.el-carousel__container {
  margin-top: 80px;
  overflow: hidden;
  position: relative;
  width: 100%; /* 让容器的宽度自适应 */
  height: auto; /* 保证高度自适应 */
}

/* 轮播图图片样式 */
.carousel-img {
  width: 100%;
  height: 100%; /* 保证图片填充轮播图容器 */
  object-fit: contain; /* 保证图片完整显示，按比例缩放 */
}

/* 设置不同屏幕宽度下的轮播图高度 */
@media (max-width: 768px) {
  .el-carousel__container {
    height: 250px !important; /* 小屏幕设备 */
  }

  /* 调整轮播图左右切换按钮位置和大小 */
  .el-carousel__arrow--left,
  .el-carousel__arrow--right {
    width: 30px !important;
    height: 30px !important;
    font-size: 14px !important;
    top: 50% !important;
    transform: translateY(-50%);
  }

  /* 调整指示点大小和位置 */
  .el-carousel__indicator {
    bottom: 10px !important;
    font-size: 12px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .el-carousel__container {
    height: 400px !important; /* 中等屏幕设备 */
  }

  /* 调整轮播图左右切换按钮位置和大小 */
  .el-carousel__arrow--left,
  .el-carousel__arrow--right {
    width: 40px !important;
    height: 40px !important;
    font-size: 18px !important;
    top: 50% !important;
    transform: translateY(-50%);
  }

  /* 调整指示点大小和位置 */
  .el-carousel__indicator {
    bottom: 20px !important;
    font-size: 16px !important;
  }
}

@media (min-width: 992px) {
  .el-carousel__container {
    height: 600px !important; /* 大屏幕设备 */
  }

  /* 调整轮播图左右切换按钮位置和大小 */
  .el-carousel__arrow--left,
  .el-carousel__arrow--right {
    width: 50px !important;
    height: 50px !important;
    font-size: 20px !important;
    top: 50% !important;
    transform: translateY(-50%);
  }

  /* 调整指示点大小和位置 */
  .el-carousel__indicator {
    bottom: 30px !important;
    font-size: 18px !important;
  }
}
</style>
