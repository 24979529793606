<template>
  <!-- 专利 -->
  <div class="patent wrapper">
    <h3>核心专利&荣誉</h3>
    <ul>
      <li>
        <img src="@/assets/images/专利/专利证书.jpg" alt="" loading="lazy" />
      </li>
      <li>
        <img src="@/assets/images/专利/专利2.jpg" alt="" loading="lazy" />
      </li>
      <li>
        <img src="@/assets/images/专利/专利3.jpg" alt="" loading="lazy" />
      </li>
      <li>
        <img src="@/assets/images/专利/专利3.jpg" alt="" loading="lazy" />
      </li>
      <li>
        <img src="@/assets/images/专利/专利4.jpg" alt="" loading="lazy" />
      </li>
      <li>
        <img src="@/assets/images/专利/专利5.jpg" alt="" loading="lazy" />
      </li>
      <li>
        <img src="@/assets/images/专利/专利6.jpg" alt="" loading="lazy" />
      </li>
      <li>
        <img src="@/assets/images/专利/专利7.jpg" alt="" loading="lazy" />
      </li>
      <li>
        <img src="@/assets/images/专利/获奖1.png" alt="" loading="lazy" />
      </li>
      <li>
        <img src="@/assets/images/专利/获奖2.jpg" alt="" loading="lazy" />
      </li>
      <li>
        <img src="@/assets/images/专利/获奖3.png" alt="" loading="lazy" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.patent h3 {
  margin-bottom: 60px;
  font-size: 62px;
  text-align: center;
  font-weight: 700;
  color: green;
}

.patent ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.patent ul li img {
  margin-bottom: 10px;
  width: 400px;
}

.patent ul li img:hover {
  transition: all 2s;
  transform: scale(1.7);
}
</style>
