<template>
  <div class="HmRegister-wrapper">
    <div class="container">
      <!-- 卡片 -->
      <div class="HmRegister-card">
        <h3>新用户注册</h3>
        <form class="HmRegister-form">
          <div data-prop="username" class="HmRegister-form-item">
            <span class="iconfont icon-zhanghao"></span>
            <input name="username" type="text" placeholder="设置用户名称" />
            <span class="msg"></span>
          </div>
          <div data-prop="phone" class="HmRegister-form-item">
            <span class="iconfont icon-shouji"></span>
            <input name="phone" type="text" placeholder="输入手机号码  " />
            <span class="msg"></span>
          </div>
          <div data-prop="code" class="HmRegister-form-item">
            <span class="iconfont icon-zhibiaozhushibiaozhu"></span>
            <input name="code" type="text" placeholder="短信验证码" />
            <span class="msg"></span>
            <a class="code" href="javascript:;">发送验证码</a>
          </div>
          <div data-prop="password" class="HmRegister-form-item">
            <span class="iconfont icon-suo"></span>
            <input
              name="password"
              type="password"
              placeholder="设置6至20位字母、数字和符号组合"
            />
            <span class="msg"></span>
          </div>
          <div data-prop="confirm" class="HmRegister-form-item">
            <span class="iconfont icon-suo"></span>
            <input
              name="confirm"
              type="password"
              placeholder="请再次输入上面密码"
            />
            <span class="msg"></span>
          </div>
          <div class="HmRegister-form-item pl50">
            <i class="iconfont icon-queren"></i>
            已阅读并同意<i>《用户服务协议》</i>
          </div>
          <div class="HmRegister-form-item">
            <button class="submit">下一步</button>
            <!-- <a class="submit" href="javascript:;">下一步</a> -->
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.HmRegister-wrapper {
  background: #f5f5f5;
  line-height: 1.4;
}

.HmRegister-wrapper .container {
  width: 1240px;
  margin: 0px auto;
  padding: 50px 0;
}

.HmRegister-card {
  width: 100%;
  height: 800px;
  background: #fff;
}

.HmRegister-card h3 {
  font-size: 26px;
  font-weight: normal;
  color: #999999;
  padding-left: 50px;
  height: 180px;
  line-height: 180px;
}

.HmRegister-form {
  padding-left: 460px;
}

.HmRegister-form .HmRegister-form-item {
  position: relative;
  padding-bottom: 24px;
}

.HmRegister-form .HmRegister-form-item .msg {
  color: #ff4d4f;
  position: absolute;
  left: 2px;
  bottom: 2px;
}

.HmRegister-form .HmRegister-form-item.pl50 {
  padding-left: 40px;
  cursor: pointer;
}

.HmRegister-form .HmRegister-form-item span.iconfont {
  position: absolute;
  left: 15px;
  top: 13px;
  color: #666;
  font-size: 18px;
}

.HmRegister-form .HmRegister-form-item i {
  color: #27ba9b;
  font-size: 14px;
}

.HmRegister-form .HmRegister-form-item input {
  width: 300px;
  height: 50px;
  border: 1px solid #e4e4e4;
  padding-left: 40px;
}

.HmRegister-form .HmRegister-form-item.error input {
  border-color: #ff4d4f;
}

.HmRegister-form .HmRegister-form-item input::placeholder {
  color: #cccccc !important;
}

.HmRegister-form .HmRegister-form-item .submit {
  width: 300px;
  height: 50px;
  background: #27ba9b;
  border-radius: 4px;
  display: block;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
}

.HmRegister-form .HmRegister-form-item .code {
  position: absolute;
  left: 190px;
  top: 16px;
  width: 100px;
  color: #27ba9b;
  text-align: right;
}

.HmRegister-form .HmRegister-form-item .code.ing {
  color: #cccccc;
}

.HmRegister-steps {
  display: flex;
  justify-content: space-between;
  width: 720px;
  height: 96px;
  margin: 0 auto;
}

.HmRegister-steps .item {
  position: relative;
  width: 48px;
  height: 48px;
}

.HmRegister-steps .item .text {
  text-align: center;
  width: 120px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 63px;
  color: #999;
}

.HmRegister-steps .item .step {
  border: 2px solid #e4e4e4;
  color: #ccc;
  background: #fff;
  border-radius: 50%;
  line-height: 46px;
  text-align: center;
  font-size: 28px;
  z-index: 999;
  display: block;
  position: relative;
}

.HmRegister-steps .item::after,
.HmRegister-steps .item::before {
  content: "";
  position: absolute;
  top: 23px;
  left: 24px;
  height: 2px;
  background: #e4e4e4;
  width: 120px;
}

.HmRegister-steps .item::after {
  transform: translateX(-100%);
}

.HmRegister-steps .item:first-child::after {
  display: none;
}

.HmRegister-steps .item:last-child::before {
  display: none;
}

.HmRegister-steps .item.active .text {
  color: #27ba9b;
}

.HmRegister-steps .item.active .step {
  background: #27ba9b;
  color: #fff;
  border-color: #27ba9b;
}

.HmRegister-steps .item.active::after,
.HmRegister-steps .item.active::before {
  content: "";
  background: #27ba9b;
}

.HmRegister-form-label {
  padding-left: 360px;
  margin-top: 80px;
}

.HmRegister-form-label .HmRegister-form-item {
  position: relative;
  padding-bottom: 24px;
  display: flex;
}

.HmRegister-form-label .HmRegister-form-item label {
  width: 100px;
  padding-right: 15px;
  text-align: right;
  font-size: 16px;
  height: 50px;
  line-height: 50px;
  color: #999;
}

.HmRegister-form-label .HmRegister-form-item input {
  width: 300px;
  height: 50px;
  border: 1px solid #e4e4e4;
  padding-left: 10px;
}

.HmRegister-form-label .HmRegister-form-item input::placeholder {
  color: #cccccc !important;
}

.HmRegister-form-label .HmRegister-form-item input:read-only {
  background: #f5f5f5;
  color: #999;
}

.HmRegister-form-label .HmRegister-form-item .submit {
  width: 300px;
  height: 50px;
  background: #27ba9b;
  border-radius: 4px;
  display: block;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 50px;
}

.HmRegister-form-label .HmRegister-form-item .captcha {
  line-height: 50px;
  height: 50px;
}

.HmRegister-form-label .HmRegister-form-item .captcha img {
  width: 134px;
  height: 50px;
}

.HmRegister-form-label .HmRegister-form-item .captcha a {
  color: #27ba9b;
}

.HmRegister-form-label .HmRegister-form-item .code {
  position: absolute;
  left: 310px;
  top: 16px;
  color: #27ba9b;
}

.HmRegister-form-label .HmRegister-form-item .code.ing {
  color: #cccccc;
}

.HmRegister-success-box {
  padding-top: 250px;
  text-align: center;
  color: #999999;
}

.HmRegister-success-box .iconfont {
  font-size: 80px;
  color: #1dc779;
}

.HmRegister-success-box .tit {
  font-size: 20px;
  padding: 20px 0;
}

.HmRegister-success-box .desc {
  font-size: 16px;
  padding-bottom: 20px;
}

.HmRegister-success-box .btn {
  width: 300px;
  height: 50px;
  background: #27ba9b;
  border-radius: 4px;
  display: block;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 50px;
  margin: 0 auto;
}
</style>
