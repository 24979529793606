<template>
  <div>
      <!-- 产品介绍 -->
  <div class="about wrapper">
    <p><strong>产品介绍/</strong>Product introduction</p>
    <div class="people">
      <div class="left">
        <img src="@/assets/images/产品设想/已实现产品.png" alt="">
      </div>
      <div class="right">
        <a href=" http://e.tb.cn/h.gDxG1RH7WCoGucy?tk=YH2N3NWCXIE HU0854">
          <h3>轻久绿电池</h3>
          武汉华鸣新能源有限公司戴乐天博士研究团队自主研制了全球首创的绿电池，
          即为一款专为光伏储能小型化的创新方案。绿电池不仅高效环保而且可以自发电，
          一块绿电池的预期寿命在5-10年，旨在将绿色电力转化为我们日常生活的清洁动力，
          开辟电池新品类。
        </a>
      </div>
    </div>
    <p style="color: red; text-align: center;">点击产品介绍文字即可直达淘宝页面，详情敬请移步淘宝页面</p>
  </div>

  <!-- 产品设想 -->
  <div class="about wrapper">
    <p><strong>产品设想/</strong>product idea</p>
    <div class="people">
      <div class="left">
        <img src="@/assets/images/产品设想/产品设想1.png" alt="">
      </div>
      <div class="right">
        <h3>分布式加氢站</h3>
        光伏额定发电功率：0.5 MW <br>
        额定产氢速率：400 标方/小时 <br>
        应用场景： 分布式加氢站 <br>
      </div>
    </div>
  </div>

  <div class="about wrapper">
    <p><strong>产品设想/</strong>product idea</p>
    <div class="people">
      <div class="left">
        <img src="@/assets/images/产品设想/产品设想2.png" alt="">
      </div>
      <div class="right">
        <h3>炼钢厂补氢</h3>
        光伏额定发电功率：1 MW <br>
        额定产氢速率：800 标方/小时 <br>
        应用场景： 炼钢厂补氢 <br>

      </div>
    </div>
  </div>

  <div class="about wrapper">
    <p><strong>产品设想/</strong>product idea</p>
    <div class="people">
      <div class="left">
        <img src="@/assets/images/产品设想/产品设想3.jpg" alt="">
      </div>
      <div class="right">
        <h3>化工厂补氢</h3>
        光伏额定发电功率：1.5 MW <br>
        额定产氢速率：1200 标方/小时 <br>
        应用场景： 化工厂补氢 <br>
      </div>
    </div>
  </div>
  </div>
</template>

<script scope>
export default {

}
</script >

<style lang="less" scoped>

.about p {
  font-size: 32px;
}

.about .people {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
}

.about .people .left img {
  width: 600px;
  height: 350px;
}

.about .taobao {
  color: red;
  text-align: center;
}

.about .people .right {
  padding: 40px;
  height: 350px;
  font-size: 24px;
}

.about .people .right a:hover {
  color: green;
}

.about .people .right h3 {
  font-weight: 700;
  font-size: 26px;
  text-align: center;
}
</style>
