import Vue from 'vue'
import App from './App.vue'
// import axios from 'axios'
import VueLazyload from 'vue-lazyload'
import router from './router'// 引入路由
import store from './store'// 引入store
import ElementUI from 'element-ui'
// 在需要使用的组件内部引入
// import * as echarts from 'echarts';
import 'element-ui/lib/theme-chalk/index.css'// 引入样式
import '@/assets/styles/base.css'

Vue.config.productionTip = false
Vue.use(ElementUI)// 使用
Vue.use(VueLazyload, {
  loading: require('@/assets/images/loading.gif'), // 设置图片加载时的占位图
  attempt: 1 // 尝试加载的次数
})

new Vue({
  // 注入路由
  router,
  // 注入store
  store,
  render: h => h(App)
}).$mount('#app')
