<template>
  <div>
    <!-- 登录内容 -->
    <div class="HmLogin-main">
      <div class="wrapper">
        <form action="" autocomplete="off">
          <div class="box">
            <div class="tab-nav">
              <a
                href="javascript:;"
                @click="show(0)"
                :class="{ active: ShowValue === true }"
                data-id="0"
                >用户登录</a
              >
              <a
                href="javascript:;"
                @click="show(1)"
                :class="{ active: ShowValue === false }"
                data-id="1"
                >管理员登录</a
              >
            </div>
            <div class="tab-pane" v-if="ShowValue">
              <div class="link">
                <a href="javascript:;">H2M华鸣新能源</a>
              </div>
              <div class="input">
                <span class="iconfont icon-zhanghao"></span>
                <input
                  required
                  type="text"
                  placeholder="请输入用户名称/手机号码"
                  name="username"
                />
              </div>
              <div class="input">
                <span class="iconfont icon-suo"></span>
                <input
                  required
                  type="password"
                  placeholder="请输入密码"
                  name="password"
                />
              </div>
              <div class="agree">
                <label for="my-checkbox">
                  <input
                    type="checkbox"
                    value="1"
                    id="my-checkbox"
                    class="remember"
                    name="agree"
                  />
                  <span class="iconfont icon-xuanze"></span>
                </label>
                我已同意 <a href="javascript:;">《服务条款》</a>和<a
                  href="javascript:;"
                  >《服务条款》</a
                >
              </div>
              <div class="button clearfix">
                <button type="submit" class="dl">登 录</button>
                <!-- <a class="dl" href="./center.html">登 录</a> -->
                <a class="fl" href="./forget.html">忘记密码？</a>
                <a class="fr" href="#/HmRegister">免费注册</a>
              </div>
            </div>
            <div class="tab-pane" v-else>
              <div class="link">
                <a href="javascript:;">H2M华鸣新能源</a>
              </div>
              <div class="input">
                <span class="iconfont icon-zhanghao"></span>
                <input
                  required
                  type="text"
                  placeholder="请输入用户名称/手机号码"
                  name="username"
                />
              </div>
              <div class="input">
                <span class="iconfont icon-suo"></span>
                <input
                  required
                  type="password"
                  placeholder="请输入密码"
                  name="password"
                />
              </div>
              <div class="agree">
                <label for="my-checkbox">
                  <input
                    type="checkbox"
                    value="1"
                    id="my-checkbox"
                    class="remember"
                    name="agree"
                  />
                  <span class="iconfont icon-xuanze"></span>
                </label>
                我已同意 <a href="javascript:;">《服务条款》</a>和<a
                  href="javascript:;"
                  >《服务条款》</a
                >
              </div>
              <div class="button clearfix">
                <button type="submit" class="dl">登 录</button>
                <!-- <a class="dl" href="./center.html">登 录</a> -->
                <!-- <a class="fl" href="./forget.html">忘记密码？</a>
              <a class="fr" href="./register.html">免费注册</a> -->
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      ShowValue: true
    }
  },
  methods: {
    show (index) {
      this.ShowValue = (index === 0) // 切换ShowValue的值
    }
  }
}
</script>

<style lang="less" scoped>
.HmLogin-main {
  height: 650px;
  background: url(@/assets/images/Login.jpg) no-repeat center/cover;
}

.HmLogin-main .wrapper {
  width: 1170px;
  height: 100%;
  margin: 0 auto;
  position: relative;
}

.HmLogin-main .wrapper .box {
  margin-top: 130px;
  width: 380px;
  box-shadow: 0px 0px 25px 0px rgba(3, 3, 3, 0.14);
  position: absolute;
  top: 60px;
  right: 0px;
  background: #fff;
  padding: 20px 40px;
}

.HmLogin-main .wrapper .box .tab-nav {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.HmLogin-main .wrapper .box .tab-nav::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 8px;
  width: 1px;
  height: 20px;
  background: #ededed;
}

.HmLogin-main .wrapper .box .tab-nav a {
  font-size: 18px;
  height: 35px;
  line-height: 35px;
}

.HmLogin-main .wrapper .box .tab-nav a.active {
  border-bottom: 2px solid #17bb9b;
}

.HmLogin-main .wrapper .box .tab-pane .code {
  display: block;
  margin: 44px auto;
}

.HmLogin-main .wrapper .box .tab-pane .link {
  text-align: right;
  padding-top: 12px;
}

.HmLogin-main .wrapper .box .tab-pane .link a {
  color: #41a955;
}

.HmLogin-main .wrapper .box .tab-pane .input {
  margin-top: 30px;
  border: 1px solid #41a955;
  width: 100%;
  height: 36px;
  margin-top: 17px;
  display: flex;
  background: #cfcdcd;
}

.HmLogin-main .wrapper .box .tab-pane .input .iconfont {
  color: #fff;
  width: 34px;
  height: 34px;
  text-align: center;
  line-height: 34px;
  font-size: 20px;
}

.HmLogin-main .wrapper .box .tab-pane .input input {
  flex: 1;
  padding-left: 15px;
  background: #fff;
}

.HmLogin-main .wrapper .box .tab-pane .input input::placeholder {
  color: #b3b3b3 !important;
}

.HmLogin-main .wrapper .box .tab-pane .agree {
  color: #b3b3b3;
  padding: 17px 0;
}

.HmLogin-main .wrapper .box .tab-pane .agree a {
  color: #0673b1;
}

/* .HmLogin-main .wrapper .box .tab-pane .agree > label input {
   display: none;
} */

.HmLogin-main .wrapper .box .tab-pane .agree > label input:checked + span {
  color: #41a955;
}

.HmLogin-main .wrapper .box .tab-pane .button {
  margin-bottom: 15px;
}

.HmLogin-main .wrapper .box .tab-pane .button .dl {
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #41a955;
  color: #fff;
  display: block;
  font-size: 16px;
  margin-bottom: 17px;
  border-radius: 20px;
}
</style>
