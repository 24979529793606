<template>
  <!-- 联系我们 -->
  <div class="practice wrapper">
    <form action="">
      <strong>
        <h3>联系我们/CONTACT US</h3>
      </strong>
      <p>
        如果您有任何问题，请填写以下表单，以便我们更好的为您服务，标有带*号字段的是必填项目
      </p>
      <div class="concent">
        <div class="text">
          <h4>姓名<i>*</i></h4>
          <input type="text" />
        </div>
        <div class="text">
          <h4>工作邮箱<i>*</i></h4>
          <input type="text" />
        </div>
        <div class="text">
          <h4>联系电话<i>*</i></h4>
          <input type="text" />
        </div>
        <div class="text">
          <h4>公司名称<i>*</i></h4>
          <input type="text" />
        </div>
        <div class="text">
          <h4>国家<i>*</i></h4>
          <input type="text" />
        </div>
      </div>
      <!-- 提交按钮 -->
      <button type="submit">提交</button>
    </form>
    <!-- 联系我们地图区域 -->
    <div class="map wrapper">
      <div class="left">
        <h3>武汉华鸣新能源有限公司</h3>
        <p>中国武汉留学生创业园二楼 A2238</p>
        <p>联系人:戴乐天</p>
        <p>联系电话：18986130389</p>
      </div>
      <div class="right">
        <img src="@/assets/images/地图/地图.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
/* 联系我们标题 */
.practice h3 {
  font-size: 46px;
  font-weight: 700;
}

.practice p {
  font-size: 24px;
}

.practice h3,
.practice p {
  text-align: center;
}

/* 文本框 */
.concent {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.concent .text {
  margin-top: 40px;
  margin-right: 20px;
  margin-bottom: 40px;
}

.concent .text input {
  width: 600px;
  height: 40px;
  border: #333 solid 1px;
}

.concent .text h4 i {
  color: red;
}

/* 提交按钮 */
.practice button {
  margin-bottom: 40px;
  width: 150px;
  height: 60px;
  color: #fff;
  background-color: #ed3c46;
  border: 0 solid #000;
  border-radius: 30px;
}

/* 地图区域 */
.map {
  display: flex;
  justify-content: space-between;
  margin-bottom: 120px;
  background-color: #fff;
}

.map h3 {
  font-size: 32px;
  margin-left: 60px;
  margin-top: 90px;
  margin-bottom: 40px;
}

.map p {
  color: #333;
  font-size: 24px;
  margin-left: 60px;
  margin-top: 10px;
}

.map .right img {
  width: 500px;
}
</style>
