<template>
  <!-- 快捷导航 -->
  <div class="shortcut">
    <!-- 版心 -->
    <div class="wrapper">
      <!-- 快捷导航左侧 -->
      <div class="left">
        <!-- logo图标 + 文字 -->
        <!-- h1标签提升网站排名 -->
        <h1>
          <a href="index.html">
            <!-- <h1>武汉华鸣新能源有限公司</h1> -->
            <img src="@/assets/images/logo.png" alt="" />
          </a>
        </h1>
      </div>
      <!-- 快捷导航右侧 -->
      <div class="right">
        <ul>
          <li v-for="item in list" :key="item.id">
            <a
              :href="item.href"
              :class="{ active: selected === item.id }"
              @click="Activegreen(item.id)"
              >{{ item.name }}</a
            >
          </li>
          <!-- <li>
            <a href="#/admin">管理员页面</a>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      weather: '222',
      selected: 1
    }
  },
  mounted () {
    fetch('https://devapi.qweather.com/v7/weather/now?location=101010100',
      {
        method: 'GET',
        headers: {
          Authorization: '40e72dbb3782494f92d241c1ab6ecb4e'
        }
      }).then()
  },
  methods: {
    Activegreen (id) {
      this.selected = id // 设置选中的标签ID
    }
  },
  props: {
    list: Array
  }
}
</script>

<style lang="less" scoped>
.shortcut {
  position: fixed; /* 设置为固定定位 */
  top: 0; /* 距离顶部 0 */
  left: 0; /* 距离左侧 0 */
  width: 100%; /* 宽度 100% */
  z-index: 1000; /* 确保在其他元素之上 */
  height: 80px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* 增加阴影效果 */
}

.shortcut .wrapper {
  display: flex;
  justify-content: space-between;
  height: 80px;
}

/* 快捷导航LOGO部分 left */
.shortcut .left {
  width: 200px;
  height: 80px;
}

.shortcut .left h1 {
  font-size: 0;
}

.shortcut .left img {
  width: 200px;
  height: 80px;
}

/* 快捷导航导航部分 right */
.shortcut .wrapper ul {
  display: flex;
  line-height: 80px;
}

/* 因为a是行内，靠内容撑开，而边上的线跟内容一样长 */
.shortcut .wrapper li a {
  padding: 0 15px;
  /* border-right: 1px solid #999; */
  font-size: 18px;
  font-weight: 700;
  color: #333;
}

.shortcut .wrapper li a:hover {
  font-weight: 700;
  color: green;
}

.shortcut .wrapper li:nth-child(7) a {
  border-right: 0;
}

.shortcut .wrapper .active {
  font-weight: 700;
  color: green;
}
</style>
