<template>
   <!-- 加入我们区域 -->
   <div class="join wrapper">
    <form action="">
      <div class="text">
        <input type="text" placeholder="您的姓名">
        <input type="text" placeholder="应聘职位">
        <input type="file" placeholder="您的简历">
      </div>
      <button type="submit">提交</button>
    </form>
    <h4>如果你想了解更多请联系我们</h4>
    <p>Tel：18986130389（微信同号）</p>

  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.join form .text {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.join .text input {
  margin-top: 40px;
  margin-left: 210px;
  margin-right: 200px;
  margin-bottom: 20px;
  padding: 10px;
  text-align: center;
  width: 600px;
  height: 40px;
  border: #333 solid 1px;
}

.join button {
  margin-left: 355px;
  margin-bottom: 50px;
  width: 600px;
  height: 40px;
  background-color: #ed3c46;
  color: #fff;
  text-align: center;
}

.join h4,
.join p {
  font-weight: 700;
  text-align: center;
}

.join p {
  font-size: 24px;
}
</style>
