<template>
   <!-- 新闻中心 -->
   <div class="news wrapper">
    <strong>
      <!-- 标题 -->
      <h3>新闻中心/news</h3>
      <div class="top">
        <ul>
          <li><a href="">公司新闻</a></li>
          <li><a href="">视频新闻</a></li>
          <li><a href="">行业资讯</a></li>
        </ul>
      </div>
      <!-- 新闻文本 左图 右文本 -->
      <div class="content">
        <ul>
          <li>
            <a href="https://mp.weixin.qq.com/s?__biz=MzkzNDgyODc0Nw==&mid=2247483829&idx=1&sn=fc9978f46f54c843efee7261661018dc&chksm=c2b60e11f5c187070efc3f91ea7ee4ca69a3b9e372f688ebbbe7fa5212c40337e865e1bff518&token=1029720447&lang=zh_CN#rd">
              <div class="left">
              <img src="@/assets/uploads/new5.png" alt="图片">
            </div>
            <div class="right">
                <h4>绿色闪电战：轻久绿电池半价疯抢，100件等你夺！<br></h4>
                <p>2024/11/13</p>
            </div>
            </a>
          </li>
          <li>
            <a href="https://mp.weixin.qq.com/s?__biz=MzkzNDgyODc0Nw==&mid=2247483873&idx=1&sn=2f62016472e19bd438da7b8be7daf130&chksm=c2b60e45f5c187534467b33f272d06cdcf1c295f7124dbdd1b1e93082382a422229b770b3106&token=1029720447&lang=zh_CN#rd">
              <div class="left">
              <img src="@/assets/uploads/new6.png" alt="图片">
            </div>
            <div class="right">
                <h4>揭开光伏电池的面纱：让阳光成为绿色能源的源泉<br></h4>
                <p>2024/11/13</p>
            </div>
            </a>
          </li>
          <li>
            <a href="https://mp.weixin.qq.com/s?__biz=MzkzNDgyODc0Nw==&mid=2247483896&idx=1&sn=92955063d2f2f805942317843537cd90&chksm=c2b60e5cf5c1874a20709e3c9da12687df61402b6f1a0fe128ba0e36b9474004b27d26288d69&token=1029720447&lang=zh_CN#rd">
              <div class="left">
              <img src="@/assets/uploads/new7.png" alt="图片">
            </div>
            <div class="right">
                <h4>喜报：华中科技大学 戴乐天博士 团队在荆门市创新创业大赛中荣获二等奖<br></h4>
                <p>2024/11/13</p>
            </div>
            </a>
          </li>
          <li>
            <a href="https://mp.weixin.qq.com/s?__biz=MzkzNDgyODc0Nw==&mid=2247483829&idx=1&sn=fc9978f46f54c843efee7261661018dc&chksm=c2b60e11f5c187070efc3f91ea7ee4ca69a3b9e372f688ebbbe7fa5212c40337e865e1bff518&token=1029720447&lang=zh_CN#rd">
              <div class="left">
              <img src="@/assets/uploads/new4.jpg" alt="图片">
            </div>
            <div class="right">
                <h4>绿色生活，从“博创杯”羽毛球赛启航<br></h4>
                <p>2024/11/13</p>
            </div>
            </a>
          </li>
          <li>
            <a href="https://mp.weixin.qq.com/s?__biz=MzkzNDgyODc0Nw==&mid=2247483807&idx=1&sn=aecd4bd2d0881b5ca38fb45968a587a8&chksm=c2b60e3bf5c1872d402a5f10d4e622f519e5590429f79cb39a440beb4b902299c4805b0dc492&token=1029720447&lang=zh_CN#rd">
              <div class="left">
              <img src="@/assets/uploads/new3.jpg" alt="图片">
            </div>
            <div class="right">
                <h4>首款“碳中和”电池——轻久绿电池<br></h4>
                <p>2024/11/12</p>
            </div>
            </a>
          </li>
          <li>
            <a href="">
              <div class="left">
              <img src="@/assets/uploads/new1.jpg" alt="图片">
            </div>
            <div class="right">
                <h4>创青春最强音，向未来奋力前进！我们的“钙钛矿光伏耦合绿氢制备系统”项目获得了<br>
                  第十一届全国“创青春”大赛银奖</h4>
                <p>2024/11/12</p>
            </div>
            </a>
          </li>
          <li>
            <a href="">
              <div class="left">
             <img src="@/assets/uploads/new2.jpg" alt="图片">
            </div>
            <div class="right">
                <h4>第三届武汉大学生“英雄杯”创新创业大赛决赛圆满落幕，祝愿我们的企业可以越办越好，大力发展新质生产力，为武汉市的发展贡献力量。</h4>
                <p>2024/11/12</p>
            </div>
            </a>
          </li>
        </ul>
      </div>
    </strong>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.news h3 {
  text-align: center;
  font-size: 42px;
  font-weight: 700;
}

.news .top ul {
  display: flex;
  justify-content: center;
}

.news .top ul li {
  margin: 30px;
  width: 150px;
  height: 80px;
  text-align: center;
  line-height: 80px;
}

.news .top ul li:hover {
  background-color: #fff;
}

.content ul li{
  margin-bottom: 20px;
  border-bottom: 1px dashed #000;;
}

.content ul li a{
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding: bottom 10px;
}

.content ul li img {
  margin-right: 20px;
  width: 300px;
  height: 200px;
  transition: all 0.8s;
}

.content ul li img:hover {
  transform: scale(1.1);
}

.content ul li .right{
  width: 900px;
  height: 201px;
}

.content ul li p {
  margin-top: 20px;
}
</style>
