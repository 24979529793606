<template>
<div>
  <!-- 关于我们 -->
  <div class="about wrapper">
    <p><strong>创始人/</strong>Founder of the company</p>
    <div class="people">
      <div class="left">
        <img src="@/assets/images/创始人.png" alt="">
      </div>
      <div class="right">
        <h3>戴乐天(华中科技大学在站博士后)</h3>
        <p>首届湖北省博士后创新创业大赛创新创业优秀博士后</p>
        <p>独立完成湖北省自然科学基金青年项目（基于新型锡基钙钛矿材料的柔性自供能宽频带光探测器研究）</p>
        <p>作为主要成员参与湖北省国际科技合作项目（高效率大面积钙钛矿/硅叠层太阳能电池关键技术研究）</p>
        <p>作为主要成员参与国家自然科学基金面上项目（AgBiS2 基新型太阳能电池关键技术研究）</p>
        <p>作为主要成员参与国家自然科学基金面上项目（镍基双原子催化剂电化学合成尿素及其反应机制研究）</p>
        <p>作为主要成员参与武汉市东湖高新区揭榜挂帅项目（大面积钙钛矿太阳能激光成套装备应用及产业化）</p>
        <p>作为主要成员参与湖北省光谷实验培育项目（面向钙钛矿电池大面积制造的激光设备关键技术研究）</p>
        <p>以第一作者身份发表包括 《ACS Energy Letters》，《Small》在内的相关论文6篇</p>
      </div>
    </div>
  </div>
  <!-- 更多区域 -->
    <div class="more">
      <h3>华鸣新能源产品优势</h3>
      <div class="button"><a href="more.html">查看更多</a></div>
    </div>
</div>
</template>

<script>
export default {

}
</script>

<style scoped>
/* 关于我们区域 */
.about strong {
  font-size: 46px;
}

.about p {
  font-size: 24px;
}

.about img {
  height: 400px;
}

.about .people {
  display: flex;
  justify-content: space-around;
}

.about .people .right {
  margin-top: 40px;
}

.about .people .right p {
  font-size: 14px;
}

/* 更多 */
.more {
  /* position: relative; */
  margin-top: 40px;
  padding-top: 30px;
  height: 400px;
  text-align: center;
  background-image: url(@/assets/uploads/banner11.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0px -500px;
}

.more h3 {
  font-size: 48px;
  font-weight: 700;
  color: green;
}

.more .button {
  /* position: absolute;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%); */
  margin: 0 auto;
  margin-top: 70px;
  width: 200px;
  height: 80px;
  font-size: 24px;
  font-weight: 700;
  line-height: 80px;
  border: 2px solid #fff;
  border-radius: 50px 50px;
}

.more .button:hover {
  background-color: #fff;
}
</style>
