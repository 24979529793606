<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav>
    <router-view/> -->
    <HmHeader :list="list"></HmHeader>
    <HmBanner :src="src" v-if="!isLoginPage"></HmBanner>
    <router-view></router-view>
    <HmFooter></HmFooter>
  </div>
</template>

<script>
import HmHeader from './components/HmHeader.vue'
import HmBanner from './components/HmBanner.vue'
import HmFooter from './components/HmFooter.vue'

export default {
  data () {
    return {
      list: [
        { id: 1, href: '#/HmIndex', name: '首页' },
        { id: 2, href: '#/HmAbout', name: '关于我们' },
        { id: 3, href: '#/HmPatent', name: '技术和专利' },
        { id: 4, href: '#/HmProduct', name: '产品和解决方案' },
        { id: 5, href: '#/HmNews', name: '新闻中心' },
        { id: 6, href: '#/HmPractice', name: '联系我们' },
        { id: 7, href: '#/HmJoins', name: '加入我们' },
        { id: 8, href: '#/HmLogin', name: '登录' }
      ],
      src: [
        { id: 1, href: require('@/assets/uploads/banner1.png') },
        { id: 2, href: require('@/assets/uploads/banner2.png') },
        { id: 3, href: require('@/assets/uploads/banner3.png') },
        { id: 4, href: require('@/assets/uploads/banner4.png') },
        { id: 5, href: require('@/assets/uploads/banner5.png') },
        { id: 6, href: require('@/assets/uploads/banner6.png') },
        { id: 7, href: require('@/assets/uploads/banner7.png') },
        { id: 8, href: require('@/assets/uploads/banner8.png') },
        { id: 9, href: require('@/assets/uploads/banner9.png') },
        { id: 10, href: require('@/assets/uploads/banner10.png') },
        { id: 11, href: require('@/assets/uploads/banner11.png') },
        { id: 12, href: require('@/assets/uploads/banner12.png') },
        { id: 13, href: require('@/assets/uploads/banner13.png') },
        { id: 14, href: require('@/assets/uploads/banner14.png') },
        { id: 15, href: require('@/assets/uploads/banner15.png') },
        { id: 16, href: require('@/assets/uploads/banner16.png') }
      ]
    }
  },
  computed: {
  // 判断当前路由是否为登录页
    isLoginPage () {
      return this.$route.path === '/HmLogin' || this.$route.path === '/HmRegister'
    }
  },
  components: {
    HmHeader,
    HmBanner,
    HmFooter
  }
}

</script>
<style lang="less">
</style>
