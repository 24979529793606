<template>
    <!-- 公司介绍区域 -->
  <div class="introduce">
    <!--版心居中  -->
    <div class="wrapper">
      <h3><strong>关于华鸣新能源</strong></h3>
      <!-- 文字介绍 -->
      <div class="content">
        <p>
          武汉华鸣新能源有限公司是由华中科技大学戴乐天博士后团队创立的一家专注于绿色新能源技术应用和开发的设备及服务提供商，<br>
          为客户提供钙钛矿太阳能电池组件、绿氢制备设备等产品及相关技术支持和服务。
        </p>
      </div>
      <!-- 图片 -->
      <div class="img"></div>
      <!-- 核心技术一 -->
      <strong>
        <h3>核心技术一:钙钛矿太阳能电池全印刷低温制备技术</h3>
      </strong>
      <div class="body">
        <div class="left">
          <p>钙钛矿太阳能电池全印刷低温制备技术 <br>
            为电解水制氢提供廉价电力我们采用自主<br>
            研发的全印刷低温制备技术制备钙钛矿太阳<br>
            能电池,大大简化了光伏电池的制备流程，<br>
            与传统晶硅电池相比，在光电转换效率一样<br>
            的情况下，成本降低了一半，为电解水制氢<br>
            提供廉价电力，此项技术的应用可以降低约35%<br>
            的制氢成本，有效地解决了电力成本高的难题。<br>
          </p>
        </div>
        <div class="right">
          <img src="@/assets/images/创客中国-华科光伏制氢团队.png" alt="">
        </div>
      </div>
      <!-- 核心技术二 -->
      <strong>
        <h3>核心技术二:单原子掺杂过渡金属催化电解水制氢技术</h3>
      </strong>
      <div class="body">
        <div class="right">
          <img src="@/assets/images/创客中国-华科光伏制氢团队2.png" alt="">
        </div>
        <div class="left">
          <p>单原子掺杂过渡金属催化电解水制氢技术<br>
            为电解水制氢提供高效廉价催化电极我们采用<br>
            单原子掺杂技术，在廉价的镍铁合金中掺入高<br>
            催化活性的钌原子，结合三维分级多孔结构设计<br>
            提升2-3倍的催化材料面积，使得整体的催化效<br>
            率提升20%，在节省使用催化材料的同时，最大化<br>
            其利用率，相较于传统的铂金等贵金属催化材料有<br>
            明显的效率和成本优势，有效解决了催化剂昂贵的<br>
            难题，相关技术荣获国家自然科学二等奖。
          </p>
        </div>
      </div>
      <!-- 核心技术三 -->
      <strong>
        <h3>核心技术三:光伏耦合制氢系统最优工作点自动调控技术
        </h3>
      </strong>
      <div class="body">
        <div class="left">
          <p>光伏耦合制氢系统最优工作点自动调控技术确保系<br>
            统稳定高效运行在最优工作点我们采用光伏制氢系统<br>
            最优工作点自动调控技术，将受天气影响波动性较大<br>
            的光伏系统与后端的制氢系统进行耦合，采用自研的<br>
            智能信号检测系统和光伏追光技术，确保制氢系统稳<br>
            定高效运行，系统耦合效率提升30%，相关技术荣获国<br>
            家自然科学二等奖。
          </p>
        </div>
        <div class="right">
          <img src="@/assets/images/创客中国-华科光伏制氢团队3.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
/* 公司介绍区域 */
.introduce h3 {
  margin-bottom: 40px;
  text-align: center;
  font-weight: 700;
  color: green;
}

.introduce p {
  margin-bottom: 40px;
  font-size: 16px;
  text-align: center;
}

.introduce .img {
  margin-bottom: 40px;
  text-align: center;
  height: 400px;
  background-image: url(@/assets/uploads/banner11.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0px -130px;
  border-radius: 40px 40px 0 0;
}
/* 公司介绍区域 */
.introduce h3 {
  margin-bottom: 40px;
  text-align: center;
  font-weight: 700;
  color: green;
}

.introduce p {
  margin-bottom: 40px;
  font-size: 16px;
  text-align: center;
}

.introduce .img {
  margin-bottom: 40px;
  text-align: center;
  height: 400px;
  background-image: url(@/assets/uploads/banner11.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0px -130px;
  border-radius: 40px 40px 0 0;
}

/* body版块 */
/* .introduce {
  background-color: #fff;
} */

.introduce .body {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 60px;
}

/* body左侧 */
.introduce .body .left {
  text-align: center;
}

.introduce .body .left p {
  margin-top: 50px;
}

/* body右侧 */
.introduce .body .right img {
  height: 300px;
}
/* body版块 */
/* .introduce {
  background-color: #fff;
} */

.introduce .body {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 60px;
}

/* body左侧 */
.introduce .body .left {
  text-align: center;
}

.introduce .body .left p {
  margin-top: 50px;
}

/* body右侧 */
.introduce .body .right img {
  height: 300px;
}
</style>
